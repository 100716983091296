.block-content-image {
    --font-size: var(--font-size-small);
    --max-width: #{(875px / 16px) * 1rem};
    --padding-h: var(--padding-m);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --padding-h: 0;
    }
}

.block-content-image {
    padding: 0 var(--padding-h);

    &__wrapper {
        margin: auto;
        max-width: rem(620);

        p {
            @include font-sans();

            color: var(--primary);
            font-size: var(--font-size);
            margin-top: #{(12px / 16px) * 1rem};
        }
    }

    @media (max-width: $smartphone) {
        &__wrapper p {
            padding: 0 var(--padding-xs);
        }
    }
}
