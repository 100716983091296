.block-slider {
    --font-size: var(--font-size-mid);
    --padding-v: var(--padding-xl);
    --padding-h: var(--padding-xxxxl);
    --arrows-pos: var(--padding-m);
    --color: var(--white);
    --bg: var(--black);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-small);
        --arrows-pos: 0;
    }
}

.block-slider {
    @include font-sans();

    background-color: var(--bg);
    bottom: 0;
    color: var(--color);
    font-size: var(--font-size);
    left: 0;
    overflow: hidden;
    position: fixed;
    right: 0;
    top: 0;

    &__wrapper {
        height: 100%;
        overflow: hidden;
        position: relative;
    }

    &__slider {
        display: flex;
        height: 100%;
        width: 100%;
    }

    .arrow {
        @include z-index($z-index-cookies);

        cursor: pointer;
        display: block;
        padding: var(--padding-small);
        position: absolute;
        transition: opacity .3s ease-out;
        will-change: opacity;

        &[disabled] {
            cursor: not-allowed;
            opacity: .4;
            pointer-events: none;
        }

        span {
            &,
            svg {
                display: block;
                height: var(--arrow-size);
                width: var(--arrow-size);
            }

            svg {
                position: absolute;
                transition: .3s ease-out;

                path { stroke: var(--white); }
            }
        }

        &.arrow-left {
            left: var(--arrows-pos);

            svg:last-child {
                opacity: 0;
                transform: translate3d(80%, 0, 0);
            }
        }

        &.arrow-right {
            right: var(--arrows-pos);

            svg:first-child {
                opacity: 0;
                transform: translate3d(-80%, 0, 0);
            }
        }

        @include isCursor() {
            &.arrow-left:hover {
                svg:last-child {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }

                svg:first-child {
                    opacity: 0;
                    transform: translate3d(-80%, 0, 0);
                }
            }

            &.arrow-right:hover {
                right: var(--arrows-pos);

                svg:last-child {
                    opacity: 0;
                    transform: translate3d(80%, 0, 0);
                }

                svg:first-child {
                    opacity: 1;
                    transform: translate3d(0, 0, 0);
                }
            }
        }
    }

    .slide {
        align-items: center;
        display: inline-flex;
        height: 100%;
        justify-content: center;
        left: 0;
        padding: var(--padding-v) var(--padding-h);
        position: absolute;
        top: 0;
        width: 100vw;

        * {
            user-select: none;
            pointer-events: none;
            will-change: opacity;
        }

        .text,
        .media-holder img {
            opacity: 0;
        }

        &[data-type="text"] {
            @include z-index($z-index-wrap);
        }

        &[data-type="image"] {
            @include z-index($z-index-3D);
        }
    }

    .loader,
    .button {
        @include z-index($z-index-cookies);
    }

    .loader,
    .button {
        bottom: 10%;
        left: 50%;
        position: fixed;
        transform: translate3d(-50%, 50%, #{$z-index-cookies}px);
    }

    .button {
        opacity: 0;
        visibility: hidden;
    }

    @media (max-width: $smartphone) {
        .slide[data-type="image"] > div { width: 100%; }

        .loader,
        .button {
            bottom: rem(40);
        }

        .arrow {
            bottom: rem(10);
            padding: var(--padding-small);
        }
    }

    @media (min-width: $smartphone) {
        &__slider {
            left: 0;
            position: absolute;
            top: 0;
        }

        .slide {
            .text { max-width: rem(661); }

            &[data-type="image"] > div { width: 80%; }
        }

        .arrow {
            padding: var(--padding-xs);
            top: 50%;
            transform: translate3d(0, -50%, #{$z-index-cookies + "px"});
        }
    }
}