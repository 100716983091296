.menu {
    --padding-v: var(--padding-small);
    --padding-h: var(--padding-small);
    --font-size-small: var(--font-size-xsmall);
}

.menu {
    @include z-index($z-index-menu);

    align-items: center;
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    position: fixed;
    pointer-events: none;
    right: 0;
    top: 0;

    > div {
        height: 100%;
        position: relative;
        width: 100%;
    }

    svg {
        height: 100%;
        opacity: 0;
        width: 100%;
    }

    .bg,
    .numbers {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
    }

    .circles {
        position: relative;
    }

    .circles2 {
        height: 100%;
        // opacity: 0;
        position: absolute;
        width: 100%;

        height: rem(667);
        width: rem(375);

        .blabla {
            height: 0;
            border-radius: 50%;
            overflow: hidden;
            position: absolute;
            z-index: 1000;
            width: 0;

            border: 1px solid black;

            &--1 {
                height: rem(200);
                width: rem(200);
                left: 50%;
                top: 39%;
            }

            &--2 {
                height: rem(272);
                width: rem(272);
                left: 49%;
                top: 25%;
            }

            &--3 {
                height: rem(240);
                width: rem(240);
                left: 69%;
                top: 40.5%;
            }

            &--4 {
                height: rem(368);
                width: rem(368);
                left: 21%;
                top: 45%;
            }

            &--5 {
                height: rem(416);
                width: rem(416);
                left: 59%;
                top: 54.5%;
            }

            &--6 {
                left: 64%;
                top: 9%;
                height: rem(464);
                width: rem(464);
            }

            &--7 {
                left: 7%;
                top: 17.5%;
                height: rem(496);
                width: rem(496);
            }

            &--8 {
                height: rem(608);
                left: 20%;
                top: 69%;
                width: rem(608);
            }

            .media-holder {
                height: 100%;
                padding-top: 0;
                position: absolute;
                width: 100%;

                img {
                    object-fit: cover;
                }
            }
        }
    }

    // &#menu-desktop {
    //     svg {
    //         opacity: 0.3;
    //     }
    // }

    @media (min-width: $smartphone) {
        .numbers {
            display: none;
        }
    }
}

#menu-titles {
    @include z-index($z-index-header);
    @include font-sans(0.8);

    align-items: flex-start;
    color: var(--white);
    display: flex;
    height: 100%;
    justify-content: flex-end;
    flex-direction: column;
    left: 0;
    padding: #{40px / 16px * 1rem};
    position: fixed;
    pointer-events: none;
    top: 0;
    width: 100%;

    .part {
        font-size: var(--font-size-large);
        letter-spacing: rem(-1);
    }

    .title {
        @include font-sans(1);
        font-size: var(--font-size-xxlarge);
    }

    li {
        opacity: 0;
        position: absolute;
    }

    @media (max-width: $smartphone) {
        .title {
            letter-spacing: rem(-4);
        }
    }

    @media (min-width: $smartphone) {
        .title {
            letter-spacing: rem(-6);
        }
    }
}
