.timeline-scroller {
    --height: calc(var(--font-size-xxsmall) * var(--line-height-sans));

    @include z-index($z-index-header);
    @include font-sans();

    color: var(--white);
    font-size: var(--font-size-xxsmall);
    left: 0;
    margin-left: var(--padding-small);
    opacity: 0;
    pointer-events: none;
    position: fixed;

    ul {
        width: 5px;

        li {
            background: var(--white);
            border-radius: 50%;
            height: 5px;
            margin-top: var(--padding-xs);
            transition: opacity 0.5s linear;
            width: 5px;

            &:not(.active) {
                opacity: 0.4;
            }
        }
    }

    @media (max-width: $smartphone) {
        bottom: var(--padding-small);
        margin-bottom: var(--padding-xs);
    }

    @media (min-width: $smartphone) {
        top: 50%;
        transform: translate3d(0, -50%, #{$z-index-header}px);
    }
}
