#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--white);
    --bg: transparent;
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);
    --font-size: var(--font-size-small);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }
}

#Header {
    @include z-index($z-index-header);
    @include font-sans(1);

    align-items: flex-start;
    background-color: var(--bg);
    display: flex;
    font-size: var(--font-size);
    height: var(--height);
    justify-content: space-between;
    padding: var(--padding-v) var(--padding-h);
    position: fixed;
    right: 0;
    top: 0;
    transform: translate3d(0, -100%, #{$z-index-header}px);
    width: var(--width);

    .logo {
        @include basic-a();

        color: var(--color);
        text-transform: uppercase;
    }

    .toggle {
        background: transparent;
        height: calc(rem(24) + var(--padding-xs));
        padding: var(--padding-xs);
        position: relative;
        right: calc(-1 * var(--padding-xxs));
        transition: transform 0.3s ease-out;
        transform: rotate(0deg) scale(1);
        top: calc(-1 * var(--padding-xxs));
        width: calc(rem(24) + var(--padding-xs));

        .empty {
            transform: translate3d(-50%, -50%, 0) scale(1);
            transition: transform 0.3s ease-out;
        }

        .full {
            transform: translate3d(-50%, -50%, 0) scale(0);
            transition: transform 0.3s ease-out;
        }

        span {
            display: block;
            height: rem(24);
            left: 50%;
            position: absolute;
            top: 50%;
            width: rem(24);

            svg {
                height: 100%;
                width: 100%;
            }
        }
    }

    &.active {
        .empty {
            transform: translate3d(-50%, -50%, 0) scale(0);
        }

        .full {
            transform: translate3d(-50%, -50%, 0) scale(1);
        }
    }

    @include isCursor() {
        .toggle:hover {
            transform: rotate(360deg) scale(1.3);
        }
    }
}

.credits {
    @include font-sans();
    @include z-index($z-index-header);
    
    color: var(--white);
    font-size: var(--font-size-xsmall);
    padding: var(--padding-xs);
    position: absolute;
    transform: translate3d(0, 100%, 0);
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
        
    button {
        @include basic-a();

        background: transparent;
        padding: 0;

        @include isCursor() {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}
