:root {
    --font-sans: 'NormalGroteskFSL-Normal', sans-serif;
    --font-sans-italic: 'NormalGroteskFSL-NormalItalic', sans-serif;

    --font-size-xxxlarge: #{(240px / 16px) * 1rem};
    --font-size-xxlarge: #{(120px / 16px) * 1rem};
    --font-size-xlarge: #{(114px / 16px) * 1rem};
    --font-size-large: #{(48px / 16px) * 1rem};
    --font-size-l: #{(28px / 16px) * 1rem};
    --font-size-mid: #{(26px / 16px) * 1rem};
    --font-size-base: #{(22px / 16px) * 1rem};
    --font-size-basesmall: #{(18px / 16px) * 1rem};
    --font-size-small: #{(16px / 16px) * 1rem};
    --font-size-xsmall: #{(14px / 16px) * 1rem};
    --font-size-xxsmall: #{(12px / 16px) * 1rem};
    --font-size-xxxsmall: #{(10px / 16px) * 1rem};

    --line-height-sans: 1.3333;

    font-size: 1.1111vw;

    @media (max-width: $smartphone) {
        --font-size-xxlarge: #{(70px / 16px) * 1rem};
        --font-size-xlarge: #{(56px / 16px) * 1rem};
        --font-size-large: #{(30px / 16px) * 1rem};
        --font-size-l: #{(24px / 16px) * 1rem};
        --font-size-mid: #{(22px / 16px) * 1rem};
        --font-size-base: #{(18px / 16px) * 1rem};
        --font-size-basesmall: #{(12px / 16px) * 1rem};
        --font-size-xsmall: #{(12px / 16px) * 1rem};
        --font-size-xxsmall: #{(8px / 16px) * 1rem};

        font-size: 16px;
    }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 {
        line-height: $line-height;
    } @else {
        line-height: var(--line-height-sans);
    }
}

@mixin font-sans-italic($line-height: 0) {
    font-family: var(--font-sans-italic);
    font-weight: normal;
    font-style: italic;
    @if $line-height > 0 {
        line-height: $line-height;
    } @else {
        line-height: var(--line-height-sans);
    }
}
