.block-next-article {
    height: 80vh;

    @media (min-width: $smartphone) {
        height: 100vh;
    }
}


.block-trigger {
    height: 20vh;

    @media (min-width: $smartphone) {
        height: 40px;
    }
}