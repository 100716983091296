.timeline-ctas {
    opacity: 0;

    .button {
        @include z-index($z-index-header);
        @include basic-a();
        flex-direction: column;
        position: fixed;
        pointer-events: none;
        left: 50%;
        opacity: 0;
        transform: translate3d(-50%, 0, #{$z-index-header}px);

        &.visible {
            pointer-events: initial;
            visibility: visible;
        }

        .icon {
            height: rem(24);
            margin-bottom: var(--padding-small);

            svg {
                height: rem(24);
                width: rem(24);
            }
        }

        @include isCursor() {
            &:hover {
                --color: var(--white);
            }
        }
    }

    @media (max-width: $smartphone) {
        .button {
            bottom: rem(86);
        }

        .desktop {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        .button {
            bottom: var(--padding-s);
        }

        .mobile {
            display: none;
        }
    }
}
