#credits {
    --font-size-big: var(--font-size-small);
    --font-size: var(--font-size-xxsmall);
    --font-size-small: var(--font-size-xsmall);
    --padding-top: var(--padding-l);
    --padding-bottom: #{40px / 16px * 1rem};
    --padding-h: #{40px / 16px * 1rem};

    @media (max-width: $smartphone) {
        --font-size-big: var(--font-size-xsmall);
        --font-size: var(--font-size-xxxsmall);
        --font-size-small: var(--font-size-xsmall);
        --padding-top: #{30px / 16px * 1rem};
        --padding-bottom: var(--padding-small);
        --padding-h: var(--padding-small);
    }
}

#credits {
    @include font-sans();
    @include z-index($z-index-credits);

    background: var(--black);
    bottom: 0;
    color: var(--white);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;

    @include isCursor() {
        button:hover,
        a:hover {
            text-decoration: underline;
        }
    }

    .toggle {
        @include z-index($z-index-credits);

        height: rem(24);
        padding: rem(3);
        position: absolute;
        right: var(--padding-h);
        top: var(--padding-top);
        width: rem(24);

        svg {
            height: rem(18);
            width: rem(18);

            path {
                stroke: var(--white);
            }
        }
    }

    .wrapper {
        height: 100%;
        padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
        position: relative;
        width: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: var(--padding-l);
        width: 100%;

        svg {
            max-width: rem(140);
        }
    }

    .published {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: var(--padding-m);
        text-align: center;

        .label {
            display: block;
            margin-bottom: rem(10);
        }
    }

    .content {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: flex-start;
        position: relative;
        width: 100%;

        > div {
            align-items: flex-start;
            display: flex;
            justify-content: space-between;
        }

        a {
            font-size: var(--font-size-small);
            margin-top: #{80px / 16px * 1rem};
        }
    }

    .bottom {
        align-items: center;
        display: flex;
        font-size: var(--font-size-small);
        justify-content: space-between;
        margin-top: rem(85);
        width: 100%;

        .share {
            @include font-sans(1);

            align-items: center;
            display: flex;
            justify-content: flex-start;

            > * {
                margin-right: var(--padding-xs);
            }
        }

        .copied {
            opacity: 0;
            pointer-events: none;
        }

        button:not(.toggle),
        a {
            transition: transform 0.1s linear;
        }

        button:not(.toggle):hover,
        a:hover {
            text-decoration: none;
        }

        @include isCursor() {
            button:not(.toggle):hover,
            a:hover {
                transform: scale3d(1.25, 1.25, 1);
            }
        }
    }

    button {
        @include basic-a();

        background: transparent;
        padding: 0;
    }

    ul {
        width: calc(50% - rem(8));

        &:first-child {
            margin-right: rem(16);
        }

        li {
            text-align: center;

            &:not(:last-child) {
                margin-bottom: var(--padding-sm);
            }
        }
    }

    a {
        @include basic-a();
        color: currentColor;
    }

    .label {
        display: block;
        font-size: var(--font-size);
        letter-spacing: rem(2);
        margin-bottom: rem(4);
        text-transform: uppercase;
    }

    .item {
        @include font-sans(1.111);

        font-size: var(--font-size-big);
    }

    @media (max-width: $smartphone) {
        .share {
            position: relative;

            .copied {
                bottom: 100%;
                position: absolute;
                margin-right: 0;
                margin-bottom: rem(16);
                left: rem(60);
                width: rem(130);
                text-align: center;
            }
        }

        .bottom {
            margin-top: rem(65);
        }

        .published {
            margin-bottom: var(--padding-s);
        }

        .logo {
            margin-bottom: var(--padding-s);
        }

        .copyright {
            text-align: right;
            font-size: rem(11);
        }

        .content a {
            margin-top: #{50px / 16px * 1rem};
        }
    }
}
