.block-text {
    --font-size-quote: var(--font-size-large);
    --font-size-first: var(--font-size-l);
    --font-size: var(--font-size-base);
    --color: var(--primary);
    --max-width: #{(900px / 16px) * 1rem};
    --padding-v: var(--padding-large);
    --padding-h: var(--padding-m);
    --margin: var(--padding-s);
    --margin-first: var(--padding-large);

    @media (max-width: $smartphone) {
        --font-size-quote: #{40px / 16px * 1rem};
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-small);
        --margin: var(--padding-sm);
        --margin-first: var(--padding-m);
    }
}

.block-text {
    @include font-sans();

    color: var(--primary);
    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    transition: color .2s ease-out;

    &__wrapper {
        > * {
            margin: var(--margin) auto;

            &:not(blockquote) {
                max-width: rem(620);
            }

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    // .billboard + .article > &:last-child {
    //     padding-bottom: var(--padding-xxl);
    // }

    blockquote {
        @include font-sans(1);

        font-size: var(--font-size-quote);
        letter-spacing: rem(-2);
        margin: var(--padding-m) auto;
        max-width: rem(875);
        text-align: center;

        p {
            margin: 0;
        }
    }

    p {
        i {
            @include font-sans-italic();
        }
    }

    @media (min-width: $smartphone) {
        &__wrapper {
            margin: auto;
            max-width: var(--max-width);
        }

        blockquote {
            margin: var(--padding-large) auto;
        }
    }
}
