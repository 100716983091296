@mixin button {
    --color: var(--white);
    --alt-color: var(--black);
    --font-size: var(--font-size-xsmall);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xxs);
        --padding-h: var(--padding-xs);
    }

    @include font-sans(2);

    align-items: center;
    appearance: none;
    background: transparent;
    border: 1px solid var(--color);
    color: var(--color);
    cursor: pointer;
    display: flex;
    font-size: var(--font-size);
    justify-content: flex-start;
    letter-spacing: rem(2);
    padding: var(--padding-v) var(--padding-h);
    position: relative;
    text-align: center;
    text-transform: uppercase;
    transition: background 0.4s ease-out, color 0.2s ease-out;
    vertical-align: center;

    * {
        pointer-events: none;
    }

    span {
        display: block;
    }

    &--unwrapped {
        border: 0;
        padding: 0;

        @include isCursor() {
            &:hover {
                --color: rgba(var(--whiteRGB), 0.7);
            }
        }
    }

    &--border {
        background-position: bottom left;
        background-size: 100% 500%;

        @include isCursor() {
            &:hover {
                background: var(--color);
                color: var(--alt-color);
            }
        }
    }
}

.button {
    @include button;
}
