#timeline-canvas {
    @include z-index($z-index-bg);

    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
}

.dg.ac {
    @include z-index($z-index-windows);
    z-index: $z-index-windows !important;
}

.block-timeline {
    height: 100%;
    left: 0;
    position: fixed;
    pointer-events: none;
    top: 0;
    width: 100%;

    @include z-index($z-index-sidemenu);

    .article {
        background-color: transparent;
        // border-top: 2px solid #ff0000;
        // color: #ff0000;
        height: 100vh;
        // font-size: 100px;
        width: 100vw;

        img {
            height: auto;
            width: 60%;
            visibility: hidden;
        }

        &--starter { height: 250vh; }
        &--text { height: 75vh; }

        &--img {
            &:not(:last-child) { margin-bottom: 45vh; }

            img {
                height: auto;
                width: 200%;
                visibility: hidden;
            }
        }
    }

    @media (min-width: $smartphone) {
        .article {
            img { width: 30%; }

            &--img {
                img {
                    width: 75%;
                }
            }
        }
    }
}

#particles { display: none; }