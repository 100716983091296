.body-not-found {
    @include font-sans(.8);

    color: var(--white);
    text-align: center;

    article {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100vh;
    }

    h1 {
        font-size: var(--font-size-xxxlarge);
        margin-bottom: var(--padding-l);
    }

    .button {
        display: inline-block;
        text-decoration: none;
    }
}