.block-cover {
    --font-size-h1: var(--font-size-xlarge);
    --font-size-highlight: var(--font-size-large);
    --font-size: var(--font-size-basesmall);
    --font-size-label: var(--font-size-small);
    --color: var(--black);
    --bg: var(--white);
    --padding-v: #{40px / 16px * 1rem};
    --padding-h: #{40px / 16px * 1rem};
    --padding-bottom: #{300px / 16px * 1rem};

    @media (max-width: $tabletLandscape) {
        --padding-bottom: #{500px / 16px * 1rem};
    }

    @media (max-width: $smartphone) {
        --font-size-highlight: var(--font-size-l);
        --font-size-label: var(--font-size-xxxsmall);
        --padding-v: var(--padding-small);
        --padding-h: var(--padding-xs);
        --padding-bottom: #{100px / 16px * 1rem};
    }

    .scrolled & {
        --color: var(--white);
        --bg: var(--black);
    }
}

.block-cover {
    @include font-sans();

    background-color: var(--bg);
    color: var(--color);
    font-size: var(--font-size);
    min-height: 100%;
    padding: var(--padding-v) var(--padding-h) 0;
    text-align: center;
    transition: all 0.5s ease-out;

    .label {
        @include font-sans(1.2);

        display: block;
        font-size: var(--font-size-label);
        letter-spacing: rem(2);
        margin-bottom: rem(20);
    }

    h1 {
        @include font-sans(0.86);

        font-size: var(--font-size-h1);
        letter-spacing: rem(-4);
        margin: rem(10) 0 rem(45);
        text-transform: uppercase;
    }

    .highlight {
        @include font-sans(1.23);

        font-size: var(--font-size-highlight);
    }

    .bottom {
        height: var(--padding-bottom);
        pointer-events: none;
    }

    .text {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: rem(30) 0 rem(40);

        > *:first-child {
            margin-right: rem(16);
        }

        span {
            display: block;
            font-size: var(--font-size-xxxsmall);
            letter-spacing: rem(2);
            margin-bottom: rem(4);
            text-transform: uppercase;
        }
    }

    .button {
        border-color: currentColor;
        color: currentColor;
        margin: rem(50) auto 0;
    }

    @media (max-width: $smartphone) {
        #image {
            position: relative;
            padding-top: 100%;

            .media-holder {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;

                > * {
                    object-fit: cover;
                }
            }
        }
    }

    @media (min-width: $smartphone) {
        .button {
            margin: rem(72) auto 0;
        }
    }

    @media (max-width: $tabletPortrait) {
        [data-scroll-insider] {
            transform: none !important;
        }
    }

    @include isTouch() {
        [data-scroll-insider] {
            transform: none !important;
        }
    }

    @include isCursor() {
        .button:hover {
            color: var(--black);
        }
    }
}
