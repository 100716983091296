#page-loader {
    @include z-index($z-index-loader);

    align-items: center;
    justify-content: center;
    background: var(--black);
    bottom: 0;
    color: var(--white);
    display: flex;
    flex-direction: column;
    left: 0;
    position: fixed;
    pointer-events: none;
    right: 0;
    top: 0;

    .loader {
        position: relative;
    }

    .title {
        @include font-sans(1);

        font-size: var(--font-size-large);
        letter-spacing: rem(-4);
        text-transform: uppercase;
        text-align: center;
        width: rem(350);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
    }
}
