.billboard {
    @include font-sans(0.8);

    --font-size-big: var(--font-size-xxlarge);
    --font-size: var(--font-size-large);
    --padding-h: var(--padding-l);
    --padding-v: var(--padding-m);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-s);
        --padding-h: var(--padding-small);
    }

    .palette-primary & {
        --color1: var(--primary);
        --color2: var(--secondary);
    }

    .palette-secondary & {
        --color1: var(--secondary);
        --color2: var(--primary);
    }
}

.billboard {
    align-items: flex-start;
    color: var(--color1);
    display: flex;
    justify-content: flex-end;
    flex-direction: column;

    .year {
        @include font-sans();
        font-size: var(--font-size);
        letter-spacing: rem(-1);
    }

    .title {
        @include font-sans(1);
        font-size: var(--font-size-big);
    }

    .year,
    .title {
        position: relative;
        @include z-index($z-index-3D);
    }
    
    // &.billboard--white-title {
    //     .year,
    //     .title {
    //         color: var(--white);
    //     }
    // }

    &__wrapper {
        padding: var(--padding-v) var(--padding-h);
        position: relative;
        width: 100%;

        &::after {
            background-image: linear-gradient(transparent, var(--color2));
            bottom: rem(-2);
            content: '';
            left: 0;
            position: absolute;
            right: 0;

            @include z-index($z-index-bg);
        }
    }

    @media (max-width: $smartphone) {
        .title {
            letter-spacing: rem(-4);
        }

        &__bg {
            padding-top: 100%;
            position: relative;
            width: 100%;

            &--desktop {
                display: none;
            }

            .media-holder {
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;

                img {
                    object-fit: cover;
                }
            }
        }

        &__wrapper {
            background-color: var(--color2);

            &::after {
                bottom: 100%;
                height: rem(60);
            }
        }
    }

    @media (min-width: $smartphone) {
        .title {
            letter-spacing: rem(-6);
        }

        &__bg {
            width: 100%;

            &--mobile {
                display: none;
            }
        }

        &__wrapper {
            position: absolute;

            &::after {
                height: 100%;
            }
        }
    }
}

#timeline-back {
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-m);

    @media (max-width: $smartphone) {
        --padding-v: var(--padding-xs);
        --padding-h: var(--padding-xs);
    }

    @include z-index($z-index-header);

    background: transparent;
    height: calc(rem(24) + var(--padding-xs));
    padding: var(--padding-xs);
    position: fixed;
    right: calc(var(--padding-h) - var(--padding-xxs));
    top: calc(var(--padding-v) - var(--padding-xxs));
    transition: transform 0.3s ease-out;
    transform: rotate(0deg) scale(1);
    width: calc(rem(24) + var(--padding-xs));

    span {
        display: block;
        height: rem(24);
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        width: rem(24);

        svg {
            display: block;
            height: 100%;
            width: 100%;

            path {
                stroke: var(--primary);
            }
        }
    }

    @media (max-width: $smartphone) {
        right: var(--padding-xs);
        top: var(--padding-xs);
    }

    @include isCursor() {
        &:hover {
            transform: rotate(180deg) scale(1.3);
        }
    }
}
