.loader {
    @include z-index($z-index-scrollbar);

    #loader-desktop {
        height: auto;
        max-height: 50vh;
        width: rem(846);
    }

    #loader-mobile {
        height: auto;
        width: rem(250);
    }

    @media (max-width: $smartphone) {
        #loader-desktop {
            display: none;
        }
    }

    @media (min-width: $smartphone) {
        #loader-mobile {
            display: none;
        }
    }
}
